/**
 * --------------------------------------------------------------------------
 * Button visually impaired (v1.0.0): i18n.js
 * Licensed under MIT (https://github.com/veks/button-visually-impaired-javascript/blob/master/LICENSE.md)
 * --------------------------------------------------------------------------
 */

const lang = {
  'ru-RU': {
    'text': {
      'fontSize': '������ ������',
      'siteColors': '����� �����',
      'images': '�����������',
      'speech': '������ ����',
      'settings': '���������',
      'regularVersionOfTheSite': '������� ������ �����',
      'letterSpacing': '������������ ����������',
      'normal': '�����������',
      'average': '�������',
      'big': '�������',
      'lineHeight': '����������� ��������',
      'font': '�����',
      'arial': '��� �������',
      'times': '� ���������',
      'builtElements': '���������� �������� (�����, ����� � ��.)',
      'on': '��������',
      'off': '���������',
      'reset': '�������� ���������',
      'plural_0': '�������',
      'plural_1': '�������',
      'plural_2': '��������',
    },
    'voice': {
      'fontSizePlus': '������ ������ �����?���',
      'fontSizeMinus': '������ ������ ���?�����',
      'siteColorBlackOnWhite': '���� ����� ������ �� ������',
      'siteColorWhiteOnBlack': '���� ����� ����� �� �������',
      'siteColorDarkBlueOnBlue': '���� ����� ����-����� �� ��������',
      'siteColorBeigeBrown': '���� ����� ����?������ �� ��?������',
      'siteColorGreenOnDarkBrown': '���� ����� ������� �� ����-�����������',
      'imagesOn': '����������� ��������',
      'imagesOFF': '����������� ���������',
      'imagesGrayscale': '����������� �����-�����',
      'speechOn': '������ ���� �������',
      'speechOff': '������ ���� ��?������',
      'lineHeightNormal': '����������� �������� �����������',
      'lineHeightAverage': '����������� �������� �������',
      'lineHeightBig': '����������� �������� �������',
      'LetterSpacingNormal': '�������� ����� ������� �����������',
      'LetterSpacingAverage': '�������� ����� ������� �������',
      'LetterSpacingBig': '�������� ����� ������� �������',
      'fontArial': '����� ��� �������',
      'fontTimes': '����� � ���������',
      'builtElementsOn': '���������� �������� ��������',
      'builtElementsOFF': '���������� �������� ���������',
      'resetSettings': '����������� ��������� �� ������?���',
      'panelShow': '������ �������',
      'panelHide': '������ ������',
      'panelOn': '������ ����� ��� �������?�����',
      'panelOff': '������� ������ �����',
    }
  },
  'en-US': {
    'text': {
      'fontSize': 'Font size',
      'siteColors': 'Site colors',
      'images': 'Images',
      'speech': 'Speech synthesis',
      'settings': 'Settings',
      'regularVersionOfTheSite': 'Regular version Of The site',
      'letterSpacing': 'Letter spacing',
      'normal': 'Single',
      'average': 'One and a half',
      'big': 'Double',
      'lineHeight': 'Line spacing',
      'font':'Font',
      'arial': 'Sans Serif - Arial',
      'times': 'Serif - Times New Roman',
      'builtElements': 'Include inline elements (Videos, maps, etc.)',
      'on': 'Enable',
      'off': 'Disabled',
      'reset': 'Reset settings',
      'plural_0': 'pixel',
      'plural_1': 'pixels',
      'plural_2': 'pixels',
    },
    'voice': {
      'fontSizePlus': 'Font size increased',
      'fontSizeMinus': 'Font size reduced',
      'siteColorBlackOnWhite': 'Site color black on white',
      'siteColorWhiteOnBlack': 'Site color white on black',
      'siteColorDarkBlueOnBlue': 'Site color dark blue on cyan',
      'siteColorBeigeBrown': 'SiteColorBeigeBrown',
      'siteColorGreenOnDarkBrown': 'Site color green on dark brown',
      'imagesOn': 'Images enable',
      'imagesOFF': 'Images disabled',
      'imagesGrayscale': 'Images gray scale',
      'speechOn': 'Synthesis speech enable',
      'speechOff': 'Synthesis speech disabled',
      'lineHeightNormal': 'Line spacing single',
      'lineHeightAverage': 'Line spacing one and a half',
      'lineHeightBig': 'Line spacing double',
      'LetterSpacingNormal': 'Letter spacing single',
      'LetterSpacingAverage': 'Letter spacing one and a half',
      'LetterSpacingBig': 'Letter spacing letter double',
      'fontArial': 'Sans Serif - Arial',
      'fontTimes': 'Serif - Times New Roman',
      'builtElementsOn': 'Include inline elements are enabled',
      'builtElementsOFF': 'Include inline elements are disabled',
      'resetSettings': 'Default settings have been set',
      'panelShow': 'Panel show',
      'panelHide': 'Panel hide',
      'panelOn': 'Site version for visually impaired',
      'panelOff': 'Regular version of the site',
    }
  }
}

class I18n {
  constructor(options) {
    this._config = options
  }

  t(key) {
    return lang[this._config.lang]['text'][key]
  }

  v(key) {
    return lang[this._config.lang]['voice'][key]
  }
}

export default I18n
